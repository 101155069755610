import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import scheduleApolloClient from '@/lib/appsync/schedule';
import postNewUserScheduleRule from '@/graphql/userSettings/mutations/postNewUserScheduleRule.graphql';
import deleteScheduledJob from '@/graphql/schedule/mutations/deleteScheduledJob.graphql';
import deleteGroup from '@/graphql/schedule/mutations/deleteGroup.graphql';
import getGroups from '@/graphql/schedule/queries/getGroups.graphql';
import getWellTags from '@/graphql/schedule/queries/getWellTags.graphql';


import updateItemStatus from '@/graphql/schedule/mutations/updateItemStatus.graphql';


import postNewScheduledJob from '@/graphql/userSettings/mutations/postNewScheduledJob.graphql';
import putScheduledJob from '@/graphql/schedule/mutations/putScheduledJob.graphql';
import postGanttMutation from '@/graphql/schedule/mutations/postGantt.graphql';
import postGroupMutation from '@/graphql/schedule/mutations/postGroup.graphql';



import deleteWorkspaceGraphql from '@/graphql/schedule/mutations/deleteWorkspace.graphql';
import deleteTableGraphql from '@/graphql/schedule/mutations/deleteTable.graphql';
import deleteGroupGraphql from '@/graphql/schedule/mutations/deleteGroup.graphql';
import deleteItemGraphql from '@/graphql/schedule/mutations/deleteItem.graphql';



import updateTable from '@/graphql/schedule/mutations/updateTable.graphql';
import updateGroup from '@/graphql/schedule/mutations/updateGroup.graphql';
import addTagsToWell from '@/graphql/schedule/mutations/addTagsToWell.graphql';

import updateScheduleItemGraphql from '@/graphql/schedule/mutations/updateScheduleItem.graphql';


import postClusterMutation from '@/graphql/schedule/mutations/postCluster.graphql';

import putTasksParams from '@/graphql/schedule/mutations/putTasksParams.graphql';

import postNewGroup from '@/graphql/schedule/mutations/postNewGroup.graphql';
import postNewTable from '@/graphql/schedule/mutations/postNewTable.graphql';
import postNewItem from '@/graphql/schedule/mutations/postNewItem.graphql';

import postNewRule from '@/graphql/schedule/mutations/postNewRule.graphql';


import updateScheduledJobDateRanges from '@/graphql/schedule/mutations/updateScheduledJobDateRanges.graphql';


import completePrePostTasqJob from '@/graphql/schedule/mutations/completePrePostTasqJob.graphql';
import deleteRig from '@/graphql/schedule/mutations/deleteRig.graphql';
import deletePrePostTasq from '@/graphql/schedule/mutations/deletePrePostTasq.graphql';
import deleteRule from '@/graphql/schedule/mutations/deleteRule.graphql';
import deleteTableRule from '@/graphql/schedule/mutations/deleteTableRule.graphql';




import postNewPrePostTasqTemplate from '@/graphql/userSettings/mutations/postNewPrePostTasqTemplate.graphql';
import postNewRig from '@/graphql/userSettings/mutations/postNewRig.graphql';
import putScoot from '@/graphql/schedule/mutations/putScoot.graphql';



import getScheduleRulesResponse from '@/graphql/userSettings/queries/getScheduleRulesResponse.graphql';
import getScheduledJobsResponse from '@/graphql/userSettings/queries/getScheduledJobsResponse.graphql';
import getScheduleJobsV2 from '@/graphql/schedule/queries/getScheduleJobsV2.graphql';

import getComponentsResponse from '@/graphql/schedule/queries/getComponentsResponse.graphql';
import getVendorsResponse from '@/graphql/schedule/queries/getVendorsResponse.graphql';
import getEnabledRoutes from '@/graphql/schedule/queries/getEnabledRoutes.graphql';

import listAreasQuery from '@/graphql/schedule/queries/listAreas.graphql';


import getGanttChartIDs from '@/graphql/schedule/queries/getGanttChartIDsResponse.graphql';

import getGanttResponse from '@/graphql/schedule/queries/getGanttResponse.graphql';

import getScheduleTemplates from '@/graphql/schedule/queries/getScheduleTemplates.graphql';
import getColumnMapping from '@/graphql/schedule/queries/getColumnMapping.graphql';

import getPrePostTasqTemplateResponse from '@/graphql/userSettings/queries/getPrePostTasqTemplateResponse.graphql';
import PrePostTasqTemplate from '@/interfaces/schedule/PrePostTasqTemplate';
import { mapPrePostTasqTemplate } from '@/utils/scheduleJobs';
import { controllers } from 'chart.js';
import accountModule from '@/store/modules/accountModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'scheduleModule',
  store,
})
class ScheduleModule extends VuexModule {

  scheduleRules: any[] = []
  scheduleJobs: any[] = []
  scheduleComponents: any[] = []
  scheduledRigComponents: any[] = []

  activeTableGroups : any = []

  availableTags: any = [];

  activePage: any = null;

  enabledRoutes: any[] = []

  ganttChartIDs: any[] = []

  areas: any[] = []

  preTasqTemplateList: PrePostTasqTemplate[] = []
  postTasqTemplateList: PrePostTasqTemplate[] = []

  scheduledRowComponents: any[] = []

  scheduleTemplates: any[] = []

  activeGroup: any = null;

  columnMapping: any[] = []

  recentlyPostedTableID: any = null

  activeComponent: any = null
  activeComponentResponseColumns: any = null

  expandedFile: any = null

  confirmDeleteFile: any = null

  selectedTabID: any = null

  activeComponentTabs: any = []

  editGroupDetails: any = null

  @Mutation
  resetRoutes(): void {
    this.enabledRoutes = [];
  }


  @Mutation
  resetScheduleRules(): void {
    this.scheduleRules = [];
  }

  @Mutation
  resetScheduleJobs(): void {
    this.scheduleJobs = [];
  }

  @Mutation
  resetScheduleComponents(): void {
    this.scheduleComponents = [];
  }

  @Mutation
  resetScheduledRigComponents(): void {
    this.scheduledRigComponents = [];
  }


  @Mutation
  setAreas(data): void {
    this.areas = data;
  }


  @Mutation
  setActiveGroup(data): void {
	this.activeGroup = data;
  }

  
  @Mutation
  setEditGroupDetails(data): void {
	this.editGroupDetails = data
  }


  
  @Mutation
  setSelectedTabID(data): void {
	this.selectedTabID = data
  }


  
  @Mutation
  setConfirmDeleteFile(file): void {
	this.confirmDeleteFile = file
  }


  @Mutation
  setExpandedFile(file): void {
	this.expandedFile = file
  }



  @Mutation
  setRecentlyPostedTableID(data): void {
	this.recentlyPostedTableID = data;
  }

  @Mutation
  setActiveTableGroups(data): void {
	if(data){

		let updatedData = Object.keys(data).map(key => {
			if(data[key].Payload && data[key].Payload[0] && data[key].Payload[0].Tags){
			let tags = data[key].Payload[0].Tags
			data[key].tags = tags
			}
				return data[key]
		})
	

		this.activeTableGroups = updatedData
	}
  }



  @Mutation
  setColumnMapping(data): void {
	this.columnMapping = data;
  }




  @Mutation
  setScheduleTemplates(data): void {
	this.scheduleTemplates = data;
  }

  @Mutation
  setActivePage(page): void {
	this.activePage = page;
  }



  @Mutation
  setGanttJobs(get_chart_ids): void {
	this.ganttChartIDs = get_chart_ids;
  }



  @Mutation
  setGanttJob(get_chart_id): void {
	this.ganttChartIDs.push(JSON.parse(get_chart_id));
  }




  @Mutation
  addRoute(route): void {
    this.enabledRoutes.push(route);
  }

  @Mutation
  setScheduleJobs(schedule_jobs): void {
    this.scheduleJobs = schedule_jobs;
  }

  @Mutation
  setScheduleRules(schedule_rules): void {
    this.scheduleRules = schedule_rules;
  }

  @Mutation
  setScheduleComponents(schedule_components): void {
    this.scheduleComponents = schedule_components;
  }

  @Mutation
  setScheduledRigComponents(schedule_components): void {
    this.scheduledRigComponents = schedule_components;
  }

  @Mutation
  addScheduleRule(rule): void {
    this.scheduleRules.push(rule);
  }

  @Mutation
  addPreTasqTemplate(pre_tasq): void {
    this.preTasqTemplateList.push(pre_tasq);
  }

  @Mutation
  addPostTasqTemplate(post_tasq): void {
    this.postTasqTemplateList.push(post_tasq);
  }

  @Mutation
  setAllTags(tags): void {
    this.availableTags= tags.map(t => {
        return JSON.parse(t)
      });
  }

  @Mutation
  resetPrePostTasqTemplate(): void {
    this.postTasqTemplateList = [];
	this.preTasqTemplateList = [];
  }


  @Mutation
  addScheduledJob(job): void {
    this.scheduleJobs.push(job);
  }

  @Mutation
  addScheduledRig(component): void {
    this.scheduledRigComponents.push(component);
  }

  @Mutation
  setActiveComponent(component): void {
    this.activeComponent = component;
  }

  @Mutation
  setActiveComponentResponseColumns(columns): void {
    this.activeComponentResponseColumns = columns;
  }

  @Mutation
  updateActiveColumnsChecklist(data): void {
	 
	  this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked = !this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked;
	}
  

	@Mutation
	updateColumnByIndex({column_index, column}): void {
		this.activeComponentResponseColumns[column_index] = column
		// this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked = !this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked;
	}






  

	@Mutation
	setSelectedComponentTabs() {
		var tabs = [{
			ColumnType: "DETAILS",
			ColumnName: "Details",
			EditableColumnIDs: [],
			TabID: 1
		}]
		if (this.activeComponent == null) {
			this.activeComponentTabs = tabs
		}
		// @ts-ignore
		for (var x = 0; x < this.activeComponentResponseColumns.length; x++) {
			// @ts-ignore
			if (this.activeComponentResponseColumns[x].ColumnType == "FILE" || 
				// @ts-ignore
				this.activeComponentResponseColumns[x].ColumnType == "PROCEDURE") {
				tabs.push({
					// @ts-ignore
					ColumnType: this.activeComponentResponseColumns[x].ColumnType,
					// @ts-ignore
					ColumnName: this.activeComponentResponseColumns[x].CustomName,
					// @ts-ignore
					EditableColumnIDs: [this.activeComponentResponseColumns[x].CustomID],
					TabID: this.activeComponentResponseColumns[x].CustomID
				})
			} else {
				// @ts-ignore
				tabs[0].EditableColumnIDs.push(this.activeComponentResponseColumns[x].CustomID)
			}
		}

		this.activeComponentTabs = tabs
	}










	@Action
	async updateItemStatus({
		item_id,
		item_status,
	}) {
		try {
			const {
			data: {
				update_item_status: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: updateItemStatus,
			variables: {
				input: {
				  Status: item_status,
				  ItemID: item_id
				},
			},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
  
	}
  
  
  








	@Action
	async deleteTableRule({
	rule_id
	}) {
  
		try {
			const {
			data: {
			  delete_table_rule: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: deleteTableRule,
			variables: {
				input: {
					RuleID: rule_id
				},
			},
			});
  
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}
  
  




  @Action
  async deleteItem({
  item_id,
  }) {

	  try { 
		  const {
		  data: {
			delete_item: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteItemGraphql,
		  variables: {
			  input: {
				  ItemID: item_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }






  @Action
  async deleteGroup({
  group_id,
  }) {

	  try {
		  const data  = await scheduleApolloClient.mutate({
		  mutation: deleteGroupGraphql,
		  variables: {
			  input: {
				  GroupID: group_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }






  @Action
  async deleteTable({
  table_id,
  }) {

	  try {
		  const {
		  data: {
			delete_table: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteTableGraphql,
		  variables: {
			  input: {
				  TableID: table_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async deleteWorkspace({
  workspace_id,
  }) {

	  try {
		  const {
		  data: {
			delete_workspace: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteWorkspaceGraphql,
		  variables: {
			  input: {
				  WorkspaceID: workspace_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  

  @Action
  async updateScheduleItem({
  item_id,
  group_id = null,
  columns
  }) {

		var input = {
			Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			ItemID: item_id,
			Columns: columns,
		}
		if (group_id != null) {
			input["GroupID"] = group_id
		}
	  try {
		  const {
		  data: {
			update_scheduled_item: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateScheduleItemGraphql,
		  variables: {
			  input: input,
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async updateTableName({
  table_id,
  table_name
  }) {

	  try {
		  const {
		  data: {
			update_table: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateTable,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  TableID: table_id,
				  TableTitle: table_name
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }








  @Action
  async updateGroupColumns({
  group_id,
  columns
  }) {
	  try {
		  const {
		  data: {
			update_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateGroup,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  GroupID: group_id,
				  Columns: columns
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }











  @Action
  async updateGroupName({
  group_id,
  group_name
  }) {
	  try {
		  const {
		  data: {
			update_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateGroup,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  GroupID: group_id,
				  GroupName: group_name
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async addTagsToWell({
  node_id,
  tags
  }) {
	  try {
		  const data  = await scheduleApolloClient.mutate({
		  mutation: addTagsToWell,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  NodeID: node_id,
				  Tags: tags
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async updateGroupDetails({
  group_id,
  payload,
  columns
  }) {
	  try {
		  const {
		  data: {
			update_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateGroup,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  GroupID: group_id,
				  Payload: payload,
				  Columns: columns,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }





  @Action
  async postNewRule({
  workspace_id,
  table_id,
  rule_json,
  }) {

	  try {
		  const {
		  data: {
			post_new_rule: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewRule,
		  variables: {
			  input: {
				Username: accountModule.user.email.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				WorkspaceID: workspace_id,
				RuleJson: rule_json,
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }










  @Action
  async postNewGroupItem({
  username,
  workspace_id,
  table_id,
  group_id,
  columns,
  prediction_id = null
  }) {
	let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
    }

	var input = {
		Username: username.toLowerCase(),
		Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		TableID: table_id,
		WorkspaceID: workspace_id,
		Columns: columns,
		GroupID: group_id,
		Usage: usage
	  }
	  if (prediction_id != null) {
		input["PredictionID"] = prediction_id
	  }
	  try {
		  const {
		  data: {
			post_new_item: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewItem,
		  variables: {
			  input: input,
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async getTableGroups({
  table_id
  }) {
	  try {
		  const {
		  data: {
			get_groups
		  },
		  // eslint-disable-next-line no-tabs
		  } = await scheduleApolloClient.query({
		  query: getGroups,
		  variables: {
			  input: {
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				ExcludeChildren: false,
			  },
		  },
		  });

		  if(get_groups.Results) {
        this.setActiveTableGroups(JSON.parse(get_groups.Results));
		  }

		  console.log(JSON.parse(get_groups.Results));
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }


  @Action
  async getTagsForWell({
  node_id
  }) {
	  try {

		
			let input = {
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			}

			if(node_id !== null){
				// @ts-ignore
				input.NodeID = node_id
			}
		
		const {
			data: {
				get_tags: wellTags
			},
			// eslint-disable-next-line no-tabs
			}  = await scheduleApolloClient.query({
		  query: getWellTags,
		  variables: {
			  input
		  },
		  });
		//   console.log((wellTags.Results))
		//   if(wellTags.Results){
			  return wellTags.Results.length ? wellTags.Results : []
		//   }
	
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async postNewTableGroup({
  username,
  workspace_id,
  table_id,
  group_name,
  columns
  }) {
	let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
	}

	  try {
		  const {
		  data: {
			post_new_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewGroup,
		  variables: {
			  input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				WorkspaceID: workspace_id,
				Columns: columns,
				GroupName: group_name,
				Usage: usage
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }


  @Action
  async postNewTableGroupPage({
  username,
  workspace_id,
  table_id,
  group_name,
  columns,
  payload
  }) {
	let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
	}

	  try {
		  const {
		  data: {
			post_new_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewGroup,
		  variables: {
			  input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				WorkspaceID: workspace_id,
				Columns: columns,
				GroupName: group_name,
				Usage: usage,
				Payload: payload,
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async postNewWorkspaceTable({
	username,
	workspace_id,
	table_title,
	}) {

		let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
	}
		try {
			const {
			data: {
			  post_new_table: result,
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewTable,
			variables: {
				input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				WorkspaceID: workspace_id,
				TableTitle: table_title,
				Usage: usage
				},
			},
			});
			this.setRecentlyPostedTableID(result.ID);
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
  }





// ******************************************
// ************* OLD SCHEDULE ***************
// ******************************************








//   @Action
//   async postNewWorkspaceTable({
// 	username,
// 	workspace_id,
// 	table_title,
// 	}) {
// 		let usage = "SCHEDULE"
// 		if(this.activePage === 'Groups'){
// 		  usage = "ASSETS"
// 		}
// 		try {
// 			const {
// 			data: {
// 			  post_new_table: result,
// 			},
// 			} = await scheduleApolloClient.mutate({
// 			mutation: postNewTable,
// 			variables: {
// 				input: {
// 				Username: username.toLowerCase(),
// 				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
// 				WorkspaceID: workspace_id,
// 				TableTitle: table_title,
// 				Usage: usage || 'SCHEDULE',
// 				},
// 			},
// 			});
// 			this.setRecentlyPostedTableID(result.ID)
// 		} catch (e) {
// 			console.error(e);
// 			// @ts-ignore
// 			throw new Error(e);
// 		}
//   }


















//   @Action
//   async putTasksParams({
// 	GanttChartID,
// 	TaskID,
// 	ModifyObject,
// 	Value
//   }) {

// 	  try {
// 		  const {
// 		  data: {
// 			put_tasks_params: result,
// 		  },
// 		  } = await scheduleApolloClient.mutate({
// 		  mutation: putTasksParams,
// 		  variables: {
// 			  input: {
// 				GanttChartID: GanttChartID,
// 				TaskID: TaskID,
// 				Object: ModifyObject,
// 				Value: Value
// 			  },
// 		  },
// 		  });

// 		  let well_test_data = JSON.parse(result.result);
   
// 		  let formatted_response = await this.setScheduledComponents(well_test_data);
// 		  this.resetScheduledRowComponents();
// 		  for (let x = 0; x < formatted_response.length; x++) {
// 			  const parsed_results = formatted_response[x];
// 			  this.addScheduledRow(parsed_results);
// 		  }

// 	  } catch (e) {
// 		  console.error(e);
// 		  // @ts-ignore
// 		  throw new Error(e);
// 	  }
//   }


//   @Action
//   async postCluster({
// 	GanttChartID,
// 	GroupMapPath,
// 	NodeIDs,
// 	DefaultDurationHours,
// 	LastDateCompleted,
// 	ClusterParams
//   }) {

// 	  try {
// 		  const {
// 		  data: {
// 			post_cluster: {
// 			  },
// 		  },
// 		  } = await scheduleApolloClient.mutate({
// 		  mutation: postClusterMutation,
// 		  variables: {
// 			  input: {
// 				GanttChartID: GanttChartID,
// 				GroupMapPath: GroupMapPath,
// 				DefaultDurationHours: DefaultDurationHours,
// 				NodeIDs: NodeIDs,
// 				LastDateCompleted: LastDateCompleted,
// 				ClusterParams: ClusterParams
// 			  },
// 		  },
// 		  });

// 	  } catch (e) {
// 		  console.error(e);
// 		  // @ts-ignore
// 		  throw new Error(e);
// 	  }
//   }




//   @Action
//   async postGroup({
//   gantt_chart_id,
//   group_structure
//   }) {

// 	  try {
// 		  const {
// 		  data: {
// 			post_group: {
// 			  },
// 		  },
// 		  } = await scheduleApolloClient.mutate({
// 		  mutation: postGroupMutation,
// 		  variables: {
// 			  input: {
// 				GanttChartID: gantt_chart_id,
// 				GroupStructure: group_structure
// 			  },
// 		  },
// 		  });

// 	  } catch (e) {
// 		  console.error(e);
// 		  // @ts-ignore
// 		  throw new Error(e);
// 	  }
//   }






//   @Action
//   async postGantt({
//   chart_name
//   }) {

// 	  try {
// 		  const {
// 		  data: {
// 			post_gantt: {
// 			  },
// 		  },
// 		  } = await scheduleApolloClient.mutate({
// 		  mutation: postGanttMutation,
// 		  variables: {
// 			  input: {
// 				GanttChartName: chart_name,
// 				Operator: getConfigEnv('OPERATOR_LOWERCASED')
// 			  },
// 		  },
// 		  });

// 	  } catch (e) {
// 		  console.error(e);
// 		  // @ts-ignore
// 		  throw new Error(e);
// 	  }
//   }



  @Mutation
  resetScheduledRowComponents(): void {
    this.scheduledRowComponents = [];
  }

  @Mutation
  addScheduledRow(component): void {
    this.scheduledRowComponents.push(component);
  }




  @Action
  async listAreas(): Promise<void> {
    const {
      data: {
        get_areas: {
          Results: Areas
        }
      },
    } = await scheduleApolloClient.query({
      query: listAreasQuery,
      variables: {
		  input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		  }
      },
    });
	console.log("GOT AREASSS")
	console.log(Areas)
    this.setAreas(Areas);
  }






  @Action
  async getColumnMappingData({
  }): Promise<void> {

  const {
      data: {
        get_column_mapping: columnMappingTemplateData,
      },
    } = await scheduleApolloClient.query({
      query: getColumnMapping,
      variables: {
		  input: {

		  }
      },
    });
	var results = JSON.parse(columnMappingTemplateData.Results)
	this.setColumnMapping(results)

  }







  @Action
  async getScheduleTemplates({
  }): Promise<void> {

  const {
      data: {
        get_schedule_templates: scheduledTemplates,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleTemplates,
      variables: {
		  input: {

		  }
      },
    });

	var results = JSON.parse(scheduledTemplates.Results);
	this.setScheduleTemplates(results);

  }












  @Action
  setScheduledComponents(well_test_data): any {
    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
	var componentVueIDCount = 0;
	var formatted_response: any[] = [];
	for (const [header_key, header_value] of Object.entries(well_test_data)) {
		var header_rows: any[] = [];
		var header_dict = {rows:header_rows, title_row: {selected: true, selection_id: header_key, selection_name: header_key}};
		var testers_dict = header_value;

		// @ts-ignore
		for (const [tester_key, tester_value] of Object.entries(testers_dict)) {
			var header_row_dict_components_array: any[] = [];
			var header_row_dict = {components:header_row_dict_components_array, title_row:{selected: true, selection_id:tester_key, selection_name: tester_key}};

			// @ts-ignore
			for (var x = 0; x < tester_value.length; x++) {
				// @ts-ignore
				var start_date = new Date(tester_value[x].TargetDate);
				// @ts-ignore
				var end_date = new Date(tester_value[x].TargetDate);

				// @ts-ignore
				end_date.setDate(end_date.getDate() + (Math.ceil(tester_value[x].CurrentDurationHours / 24)));

				var start_date_string = start_date.getFullYear() + "-" + pad(start_date.getMonth() + 1, 2, '0') + "-" + pad(start_date.getDate(), 2, '0') + "T" + pad(start_date.getHours(), 2, '0') + ":" + pad(start_date.getMinutes(), 2, '0')+ ":" + pad(start_date.getSeconds(), 2, '0');
				var end_date_string = end_date.getFullYear() + "-" + pad(end_date.getMonth() + 1, 2, '0') + "-" + pad(end_date.getDate(), 2, '0') + "T" + pad(end_date.getHours(), 2, '0') + ":" + pad(end_date.getMinutes(), 2, '0')+ ":" + pad(end_date.getSeconds(), 2, '0');
				componentVueIDCount += 1;
				var component_dict = {
					// @ts-ignore
					assignee: tester_value[x].ClusterID,
					// @ts-ignore
					component_vue_id: tester_value[x].TaskID + '' + componentVueIDCount,
					// @ts-ignore
					component_id: tester_value[x].TaskID,
					// @ts-ignore
					last_date_completed: tester_value[x].LastDateCompleted,
					// @ts-ignore
					flag_scoot: tester_value[x].FlagScoot,
					start_date: start_date_string,
					end_date: end_date_string,
					pre_tasq_checklist: [],
					post_tasq_checklist: [],
					title_row: {selected: true, selection_id:tester_key, selection_name: tester_key},
					// @ts-ignore
					well: tester_value[x].NodeID
				};
				header_row_dict_components_array.push(component_dict);


			}
			header_row_dict.components = header_row_dict_components_array;
			header_dict.rows.push(header_row_dict);
		}
		formatted_response.push(header_dict);
	}
	return formatted_response;

  }








  @Action
  async getGantt({
	  gantt_chart_id
  }): Promise<void> {

  const {
      data: {
        get_gantt: getGanttResults,
      },
    } = await scheduleApolloClient.query({
      query: getGanttResponse,
      variables: {
        input: {
			GanttChartID: gantt_chart_id
        },
      },
    });

	var well_test_data = JSON.parse(getGanttResults.results);
   
	let formatted_response = await this.setScheduledComponents(well_test_data);
	this.resetScheduledRowComponents();
	for (let x = 0; x < formatted_response.length; x++) {
		const parsed_results = formatted_response[x];
		this.addScheduledRow(parsed_results);
	}


  }






  @Action
  async getGanttChartIDs(): Promise<void> {

  const {
      data: {
        get_gantt_chart_ids: getGanttChartIDsResults,
      },
    } = await scheduleApolloClient.query({
      query: getGanttChartIDs,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });

	for (var x = 0; x < getGanttChartIDsResults.results.length; x++) {
		this.setGanttJob(getGanttChartIDsResults.results[x]);
	}

	// this.resetRoutes();
    // for (let x = 0; x < enabledRoutesResults.results.length; x++) {
    //   const parsed_results = JSON.parse(enabledRoutesResults.results[x]);
    //   this.addRoute(parsed_results);
    // }
  }




  @Action
  async getEnabledRoutes(): Promise<void> {

    const {
      data: {
        get_enabled_routes: enabledRoutesResults,
      },
    } = await scheduleApolloClient.query({
      query: getEnabledRoutes,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });
	this.resetRoutes();
    for (let x = 0; x < enabledRoutesResults.results.length; x++) {
      const parsed_results = JSON.parse(enabledRoutesResults.results[x]);
      this.addRoute(parsed_results);
    }
  }





//   delete_group(input: {GanttChartID: "", GroupStructure: ""}) {
//     error
//     result
//     success
//   }

  @Action
  async deleteWellTestGroup({
  gantt_chart_id,
  group_structure
  }) {

	  try {
		  const {
		  data: {
			delete_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteGroup,
		  variables: {
			  input: {
				GanttChartID: gantt_chart_id,
				GroupStructure: group_structure
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }





  @Action
  async deleteRule({
  rule_id
  }) {

	  try {
		  const {
		  data: {
			delete_rule: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteRule,
		  variables: {
			  input: {
				  RuleID: rule_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }









//   @Action
//   async scootFlagSelected({
//   task_id,
//   gantt_chart_id,
//   cluster_id
//   }) {

// 	  try {
// 		  const {
// 		  data: {
// 			put_scoot: {
// 			  },
// 		  },
// 		  } = await scheduleApolloClient.mutate({
// 		  mutation: putScoot,
// 		  variables: {
// 			  input: {
// 				  TaskID: task_id,
// 				  GanttChartID: gantt_chart_id,
// 				  ClusterID: cluster_id
// 			  },
// 		  },
// 		  });

// 	  } catch (e) {
// 		  console.error(e);
// 		  // @ts-ignore
// 		  throw new Error(e);
// 	  }
//   }












  @Action
  async deleteRig({
  rig_id
  }) {

	  try {
		  const {
		  data: {
			delete_rig: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteRig,
		  variables: {
			  input: {
				  RigID: rig_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async deletePrePostTasq({
  id
  }) {

	  try {
		  const
		  data = await scheduleApolloClient.mutate({
		  mutation: deletePrePostTasq,
		  variables: {
			  input: {
				  id: id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }


  @Action
  async completePrePostTasqJob({
  tasq_id,
  tasq_type,
  prediction_id
  }) {

	  try {
		  const {
		  data: {
			complete_pre_post_tasq_job: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: completePrePostTasqJob,
		  variables: {
			  input: {
				  TasqID: tasq_id,
				  TasqType: tasq_type,
				  PredictionID: prediction_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }















@Action
  async getScheduleRulesAndComponents({
    from_date,
    to_date,
  }): Promise<void> {
    this.resetScheduleRules();
    this.resetScheduleComponents();
    const {
      data: {
        get_schedule_rules: scheduleRules,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleRulesResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          from_date,
          to_date,
        },
      },
    });
    for (let x = 0; x < scheduleRules.results.length; x++) {
      const parsed_results = JSON.parse(scheduleRules.results[x]);
      this.addScheduleRule(parsed_results);
    }

    this.setScheduleComponents(JSON.parse(scheduleRules.structured_components));
  }







  @Action
  async getScheduledJobsV2({
	start_date,
    end_date,
	table_id,
	schedule_type = "Gantt"
  }): Promise<void> {
    this.resetScheduleJobs();
    const {
      data: {
        get_scheduled_jobs_v2: scheduleJobs,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleJobsV2,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          EndDate: end_date,
          StartDate: start_date,
		  TableID: table_id,
		  ScheduleType: schedule_type
        },
      },
    });
    for (let x = 0; x < scheduleJobs.results.length; x++) {
      const parsed_results = JSON.parse(scheduleJobs.results[x]);
      this.addScheduledJob(parsed_results);
    }
	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		this.addScheduledRig(parsed_results);
	}

	this.resetScheduledRowComponents();


	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		this.addScheduledRow(parsed_results);
	}



	// for (let x = 0; x < formatted_response.length; x++) {
	// 	const parsed_results = formatted_response[x];
	// 	this.addScheduledRow(parsed_results);
	// }

  }






  @Action
  async getScheduledJobs({
	start_date,
    end_date,
  }): Promise<void> {
    this.resetScheduleJobs();
    const {
      data: {
        get_scheduled_jobs: scheduleJobs,
      },
    } = await scheduleApolloClient.query({
      query: getScheduledJobsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          EndDate: end_date,
          StartDate: start_date
        },
      },
    });
    for (let x = 0; x < scheduleJobs.results.length; x++) {
      const parsed_results = JSON.parse(scheduleJobs.results[x]);
      this.addScheduledJob(parsed_results);
    }
	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		this.addScheduledRig(parsed_results);
	}


  }


  @Action
  async getPrePostTasqTemplates(): Promise<void> {
	this.resetPrePostTasqTemplate();
    const {
      data: {
        get_pre_post_tasq_templates: prePostTasqTemplates,
      },
    } = await scheduleApolloClient.query({
      query: getPrePostTasqTemplateResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });

	const templatePrePostTasqs = (prePostTasqTemplates.results).map(
		(t: PrePostTasqTemplate) => mapPrePostTasqTemplate(t),
	  );
	this.resetPrePostTasqTemplate();
	for (var x = 0; x < templatePrePostTasqs.length; x++) {
		if (templatePrePostTasqs[x].tasqType == "PreTasq") {
			this.addPreTasqTemplate(templatePrePostTasqs[x]);
		} else {
			this.addPostTasqTemplate(templatePrePostTasqs[x]);
		}
	}
  }





  @Action
  async getComponents(): Promise<void> {
    const {
      data: {
        get_components: getComponentsResults,
      },
    } = await scheduleApolloClient.query({
      query: getComponentsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }


  @Action
  async getVendors(): Promise<void> {
    const {
      data: {
        get_vendors: getVendorsResults,
      },
    } = await scheduleApolloClient.query({
      query: getVendorsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }









  @Action
  async deleteScheduledJob({
  workflow_task_id,
  }) {
	  try {
		  const {
		  data: {
			delete_scheduled_job: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteScheduledJob,
		  variables: {
			  input: {
				WorkflowTaskID: workflow_task_id,
				Operator: getConfigEnv('OPERATOR_LOWERCASED')
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }

  }








@Action
	async postNewUserScheduleRule({
	username,
	rule_conditions_dict,
	}) {
		try {
			const {
			data: {
				post_new_user_schedule_rule: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewUserScheduleRule,
			variables: {
				input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				RuleConditionsDict: rule_conditions_dict,
				},
			},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



@Action
	async postNewScheduledJob({
	job_name,
	job_operator,
	job_summary,
	job_type,
	node_id,
	procedure_id,
	role,
	selected_end_date,
	selected_start_date,
	rig_id,
	rig_name,
	username,
	pre_tasq_checklist,
	post_tasq_checklist
	}) {

		try {
			const {
			data: {
				post_scheduled_job: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewScheduledJob,
			variables: {
				input: {
					JobName: job_name,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					JobOperator: job_operator,
					JobSummary: job_summary,
					JobType: job_type,
					NodeID: node_id,
					ProcedureID: procedure_id,
					Role: role,
					RigID: rig_id,
					RigName: rig_name,
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					Username: username.toLowerCase(),
					PreTasqChecklist: pre_tasq_checklist,
					PostTasqChecklist: post_tasq_checklist
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async putScheduledJob({
	workflow_task_id,
	job_name,
	job_operator,
	job_summary,
	job_type,
	node_id,
	procedure_id,
	rig_id,
	rig_name,
	selected_end_date,
	selected_start_date,
	username,
	pre_tasq_checklist,
	post_tasq_checklist
	}) {

		try {
			const {
			data: {
				put_scheduled_job: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: putScheduledJob,
			variables: {
				input: {
					JobName: job_name,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					SelectedOperatorEmail: job_operator,
					JobSummary: job_summary,
					JobType: job_type,
					NodeID: node_id,
					ProcedureID: procedure_id,
					RigID: rig_id,
					RigName: rig_name,
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					Username: username.toLowerCase(),
					PreTasqChecklist: pre_tasq_checklist,
					PostTasqChecklist: post_tasq_checklist,
					WorkflowTaskID: workflow_task_id
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}






	@Action
	async updateScheduledJobDateRanges({
	prediction_id,
	selected_end_date,
	selected_start_date,
	rig_id,
	rig_name
	}) {
		try {
			const {
			data: {
				update_scheduled_job_date_ranges: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: updateScheduledJobDateRanges,
			variables: {
				input: {
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					PredictionID: prediction_id,
					RigID: rig_id,
					RigName: rig_name
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}





	@Action
	async postNewPrePostTasqTemplate({
		TasqType,
		TasqTitle,
		TasqSummary
	}) {

		try {
			const {
			data: {
				post_new_pre_post_tasq_template: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewPrePostTasqTemplate,
			variables: {
				input: {
					TasqType: TasqType,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					TasqTitle: TasqTitle,
					TasqSummary: TasqSummary
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}




	@Action
	async postNewRig({
		RigName,
		RigID
	}) {

		try {
			const {
			data: {
				post_new_rig: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewRig,
			variables: {
				input: {
					RigName: RigName,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					RigID: RigID
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}




}










export default getModule(ScheduleModule);
